export const articles = [
  {
    title: 'A la croisée des imaginaires et des discours en Casamance',
    description: `
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dignissimos eaque
            eveniet modi nam nihil non odio, odit provident reiciendis sit sunt suscipit tempora
            tenetur, velit? Consequuntur minus obcaecati reprehenderit?
          `,
    href: 'http://litere.usv.ro/anadiss/arhiva/anadiss24/2.%20SOW,%20Ndi%C3%A9m%C3%A9.pdf',
    date: '2020-03-16',
  },

  {
    title: 'Parlers jeunes, entre art du langage et activisme social',
    description: `
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dignissimos eaque
            eveniet modi nam nihil non odio, odit provident reiciendis sit sunt suscipit tempora
            tenetur, velit? Consequuntur minus obcaecati reprehenderit?
          `,
    href: 'http://anadiss.usv.ro/arhiva/anadiss25/9.%20Sow%20Ndiene.pdf',
    date: '2020-03-16',
  },
  {
    title:
      'Éducation, mobilité et construction de répertoires plurilingues en Casamance : une étude de cas',
    description: `
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dignissimos eaque
            eveniet modi nam nihil non odio, odit provident reiciendis sit sunt suscipit tempora
            tenetur, velit? Consequuntur minus obcaecati reprehenderit?
          `,
    href: '#',
    date: '2020-03-16',
  },
];
