import PropTypes from 'prop-types';
import { createElement } from 'react';

export const Text = ({ children, size, weight, color, as, className, ...props }) => {
  const sizes = {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg sm:text-xl',
    xl: 'text-xl sm:text-2xl',
    '2xl': 'text-2xl sm:text-3xl',
    '3xl': 'text-3xl sm:text-4xl',
    '4xl': 'text-4xl sm:text-5xl',
  };
  const weights = {
    light: 'font-light',
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold tracking-tight',
    bold: 'font-bold tracking-tight',
    extrabold: 'font-extrabold tracking-tight',
  };
  const colors = {
    'gray-500': 'text-gray-500',
    'gray-600': 'text-gray-600',
    'gray-700': 'text-gray-700',
    'gray-800': 'text-gray-800',
    'gray-900': 'text-gray-900',
    white: 'text-white',
    'secondary-600': 'text-secondary-600',
    'primary-200': 'text-primary-200',
    'primary-500': 'text-primary-500',
    'primary-900': 'text-primary-900',
    'red-300': 'text-red-300',
  };

  return createElement(as, {
    children,
    className: `${sizes[size]} ${weights[weight]} ${colors[color]} ${className}`.trim(),
    ...props,
  });
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl']).isRequired,
  as: PropTypes.oneOf(['div', 'span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'dd', 'dt'])
    .isRequired,
  weight: PropTypes.oneOf(['light', 'normal', 'medium', 'semibold', 'bold', 'extrabold'])
    .isRequired,
  color: PropTypes.oneOf([
    'gray-500',
    'gray-600',
    'gray-700',
    'gray-800',
    'gray-900',
    'white',
    'primary-200',
    'primary-900',
    'secondary-600',
    'red-300',
  ]).isRequired,
};

Text.defaultProps = {
  size: 'lg',
  weight: 'normal',
  as: 'div',
  className: '',
  color: 'gray-900',
};
