import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Text } from '../components/text';
import { formatDate } from '../utils/date';
import { articles } from '../data/articles';

const ArticleCard = ({ title, children, href, date }) => {
  return (
    <div className="py-8">
      <p className="text-sm text-gray-500">
        <time dateTime={date}>{formatDate(date)}</time>
      </p>
      <a href={href} className="mt-2 block">
        <p className="text-xl font-semibold text-gray-900">{title}</p>
        <p className="mt-3 text-base text-gray-500">{children}</p>
      </a>
      <div className="mt-3">
        <a href={href} className="text-base font-semibold text-primary-600 hover:text-primary-500">
          Lire l'article
        </a>
      </div>
    </div>
  );
};
const PublicationsPage = () => {
  return (
    <Layout>
      <Seo title="Publications" description="Voici les dernières publications de Ndiémé Sow" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 lg:pt-24 pb-8">
        <div className="text-center">
          <Text as="h1" size="4xl" weight="extrabold" color="primary-500">
            Publications
          </Text>
          <Text as="p" size="lg" color="gray-500" className="mt-4 max-w-2xl mx-auto">
            Voici mes dernières publications.
          </Text>
        </div>
        <div className="mt-6 divide divide-y max-w-3xl mx-auto border-t-2">
          {articles.map((article) => (
            <ArticleCard
              key={article.title}
              title={article.title}
              href={article.href}
              date={article.date}
            >
              {article.description}
            </ArticleCard>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PublicationsPage;
